/* josefin-sans-100normal - latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src:
    local('Josefin Sans Thin '),
    local('Josefin Sans-Thin'),
    url('./files/josefin-sans-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/josefin-sans-latin-100.woff') format('woff'); /* Modern Browsers */
}

/* josefin-sans-100italic - latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src:
    local('Josefin Sans Thin italic'),
    local('Josefin Sans-Thinitalic'),
    url('./files/josefin-sans-latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/josefin-sans-latin-100italic.woff') format('woff'); /* Modern Browsers */
}

/* josefin-sans-300normal - latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Josefin Sans Light '),
    local('Josefin Sans-Light'),
    url('./files/josefin-sans-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/josefin-sans-latin-300.woff') format('woff'); /* Modern Browsers */
}

/* josefin-sans-300italic - latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Josefin Sans Light italic'),
    local('Josefin Sans-Lightitalic'),
    url('./files/josefin-sans-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/josefin-sans-latin-300italic.woff') format('woff'); /* Modern Browsers */
}

/* josefin-sans-400normal - latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Josefin Sans Regular '),
    local('Josefin Sans-Regular'),
    url('./files/josefin-sans-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/josefin-sans-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* josefin-sans-400italic - latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Josefin Sans Regular italic'),
    local('Josefin Sans-Regularitalic'),
    url('./files/josefin-sans-latin-400italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/josefin-sans-latin-400italic.woff') format('woff'); /* Modern Browsers */
}

/* josefin-sans-600normal - latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('Josefin Sans SemiBold '),
    local('Josefin Sans-SemiBold'),
    url('./files/josefin-sans-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/josefin-sans-latin-600.woff') format('woff'); /* Modern Browsers */
}

/* josefin-sans-600italic - latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src:
    local('Josefin Sans SemiBold italic'),
    local('Josefin Sans-SemiBolditalic'),
    url('./files/josefin-sans-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/josefin-sans-latin-600italic.woff') format('woff'); /* Modern Browsers */
}

/* josefin-sans-700normal - latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Josefin Sans Bold '),
    local('Josefin Sans-Bold'),
    url('./files/josefin-sans-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/josefin-sans-latin-700.woff') format('woff'); /* Modern Browsers */
}

/* josefin-sans-700italic - latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Josefin Sans Bold italic'),
    local('Josefin Sans-Bolditalic'),
    url('./files/josefin-sans-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/josefin-sans-latin-700italic.woff') format('woff'); /* Modern Browsers */
}

